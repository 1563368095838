import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    // 创建axios实例
const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 60000
    })
    // request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const message = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 401) {
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                var domain = document.domain.split('.').slice(-2).join('.')
                location.href = 'http://passport.' + domain;
            })
        } else if (code === 500) {
            Message({
                message: message,
                type: 'error'
            })
            return Promise.reject(new Error(message))
        } else if (code == 403) {
            Message({
                message: res.data.msg,
                type: 'error'
            })
            return Promise.reject(res.data)
        } else if (code !== 200) {
            Notification.error({
                title: message
            })
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service